import React from "react";
import Home_Banner from "./Home_Banner/Home_Banner";
import Category from "./Category/Category";
import TrendingProducts from "./TrendingProducts/TrendingProducts";
import Sale from "./Sale/Sale";
import Motivation from "./Motivation/Motivation";
import { Helmet } from "react-helmet";


function HomePage() {
  return (
    <>
      <Helmet>
        <title>Home Page</title>
        <meta name="description" content="Home Page" />
      </Helmet>

      <Home_Banner />
      <Category />
      <TrendingProducts />
      <Sale />
      <Motivation />
    </>
  );
}

export default HomePage;
