import React, { useState, useEffect, useContext } from "react";
import "./Home_Banner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Context } from "../../../utils/context";
import { homeBanner } from "../../../utils/apis/common/Common";

function Home_Banner() {
  const [getHomeBanner, setHomeBanner] = useState();
  const { getData, IMG_URL, sellerId, sellerDomain } = useContext(Context);
  const getProject = async (data) => {
    const res = await homeBanner(sellerDomain);
    if (res?.success) {
      setHomeBanner(res?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  useEffect(() => {
    getProject();
  }, [sellerId]);
  const BannerSwiperDetails = [
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Homepage/Banner/Banner1.png",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Homepage/Banner/Banner2 (2).png",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Homepage/Banner/Banner1.png",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Homepage/Banner/Banner2 (2).png",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Homepage/Banner/Banner1.png",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Homepage/Banner/Banner2 (2).png",
    },
  ];
  return (
    <>
      <section className="Home-Banner">
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {getHomeBanner?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="gallery-imgs">
                <img src={IMG_URL + item?.image} className="inner-img" alt="Sofa" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  );
}

export default Home_Banner;
