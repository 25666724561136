import React, { useContext, useEffect, useState } from 'react'
import ProductCards from "../../Common_Component/ProductCards/ProductCards";
import { Context } from '../../../utils/context';
import { Products } from '../../../utils/apis/Product/Product';
const SupplierCard = ({ headingName, product }) => {
    const frequentlycardsDetails = [
        {
            images: process.env.PUBLIC_URL + "/assets/category/m1.png",
            cardtitle: "Samsung Galaxy S24 JPorter",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/category/m2.png",
            cardtitle: "Samsung Galaxy J7",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/category/m3.png",
            cardtitle: "Vivo Smart Caprute",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/category/m4.png",
            cardtitle: "Vivo S24 JPorter",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/category/m1.png",
            cardtitle: "Samsung Galaxy S24 JPorter",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/category/m2.png",
            cardtitle: "Samsung Galaxy J7",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/category/m3.png",
            cardtitle: "Vivo Smart Caprute",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/category/m4.png",
            cardtitle: "Vivo S24 JPorter",
        },
    ];


    const [products, setProduct] = useState([]);
    const { sellerId, IMG_URL } = useContext(Context);
    console.log(product);
    const getProducts = async () => {
        const res = await Products("", product?.s_sub_category_id, "", sellerId);
        if (res?.success) {
            setProduct(res?.data?.data);
        }
    }

    useEffect(() => {
        getProducts();
    }, [product]);

    return (
        <>
            <section className="frequently-bought-together-two frequently-bought-together-one">
                <div className="container">
                    <div className="row">
                        <div className="hediang-holder mb-3">
                            {/* <h2>{headingName}</h2> */}
                        </div>

                        {/* {frequentlycardsDetails.map((item, index) => (
                            <div key={index} className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-3' >
                                <ProductCards
                                    images={IMG_URL + item?.thumbnail}
                                    productId={item?.id}
                                    imgClassName="demo"
                                    cardtitle={item?.product_title}
                                />
                            </div>
                        ))} */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default SupplierCard