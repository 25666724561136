import React, { useState } from "react";
import "./Incre_Decre_btn.css"

function Incre_Decre_btn() {
  const [counter, setCounter] = useState(0);
  const [buttonStatus, setButtonStatus] = useState("true");

  function increment(counter) {
    counter++;
    setButtonStatus("");
    setCounter(counter);
  }
  function decrement(counter) {
    counter--;
    setCounter(counter);
    if (counter < 1) {
      setButtonStatus("true");
    }
  }
  return (
    <>
      <div className="Incre_Decre_btn scale-up-hor-right">
      <div>
        <button className="counter_btn minus " disabled={buttonStatus} onClick={() => decrement(counter)}> - </button>
      </div>

      <div>
        <span className="subText">{counter}</span>
      </div>

      <div>
        <button className="counter_btn plus " onClick={() => increment(counter)}>+</button>
      </div>
      </div>
    </>
  );
}

export default Incre_Decre_btn;
