import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from 'react-bootstrap';
import ProductCards from '../../../../Common_Component/ProductCards/ProductCards';
import { Link } from 'react-router-dom';
import { Context } from "../../../../../utils/context";

const CategoryProductCard = ({ products }) => {
    const [showAll, setShowAll] = useState(false);
    const [numToShow, setNumToShow] = useState(4); // State to track number of cards to show
    const { getData, IMG_URL, sellerId } = useContext(Context);

    const toggleShowAll = () => {
        setShowAll(!showAll);
        if (showAll) {
            // If currently showing all, reduce the number of rows displayed
            setNumToShow(8);
        } else {
            // If currently not showing all, show all rows
            setNumToShow(products?.length);
        }
    };
    return (
        <>
            <Row>
                {products?.data?.slice(0, numToShow).map((item, index) => (
                    <Col key={index} xxl={4} xl={4} lg={6} md={6} sm={12}>
                        <Link to={item.detailsLink}>
                            <ProductCards
                                images={IMG_URL + item?.thumbnail}
                                cardtitle={item?.product_title + ", " + item?.product_generic_name}
                                productId={item?.id}
                                imgClassName="cate-demo"
                            />
                        </Link>
                    </Col>
                ))}

                {products.length > 6 ? (
                    <>
                        {showAll ? (
                            <div className="view-all mt-4" onClick={toggleShowAll}>
                                <p className='view'>View Less</p>
                            </div>
                        ) : (
                            <div className="view-all mt-4" onClick={toggleShowAll}>
                                <p className='view'>View All</p>
                            </div>
                        )}

                    </>
                ) : ('')
                }
            </Row>
        </>
    );
};

export default CategoryProductCard;