import React from 'react'
import "./Morebrands.css";
import Heading_Holder from '../../Common_Component/Heading_Holder/Heading_Holder';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
const Morebrands = () => {
    const brandData = [
        { id: 1, logoSrc: "/assets/Product Description/parkavenue.png", name: "Raymond" },
        { id: 2, logoSrc: "/assets/Product Description/raymond.png", name: "Levi's" },
        { id: 3, logoSrc: "/assets/Product Description/parkavenue.png", name: "Nike" },
        { id: 4, logoSrc: "/assets/Product Description/van.png", name: "Adidas" },
        { id: 5, logoSrc: "/assets/Product Description/raymond.png", name: "Puma" },
    ];

    return (
        <>
            <section className='MoreBrands'>
                <div className='container'>
                    <Heading_Holder headingText={"MORE BRANDS FOR YOU"} />

                    <div className='Brandscardsec'>
                        <div className='row'>

                            <Swiper
                                slidesPerView={4}
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                // modules={[Pagination]}
                                className="mySwiper"
                                breakpoints={{
                                    0: { slidesPerView: 1 },
                                    380: { slidesPerView: 1 },
                                    485: { slidesPerView: 1 },
                                    575: { slidesPerView: 2 },
                                    768: { slidesPerView: 3 },
                                    992: { slidesPerView: 4 },
                                    1024: { slidesPerView: 4 },
                                    1200: { slidesPerView: 4 },
                                    1440: { slidesPerView: 3 },
                                    2000: { slidesPerView: 3 },
                                }}
                            >
                                {brandData.map((brand) => (
                                    <SwiperSlide key={brand.id}>

                                        <div className='card brandCard'>
                                            <div className='card-body'>
                                                <div className='LogoNamesec'>
                                                    <img src={process.env.PUBLIC_URL + brand.logoSrc} className='logoImg' alt={brand.name} />
                                                    <p>{brand.name}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </SwiperSlide>
                                ))}
                            </Swiper>




                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Morebrands