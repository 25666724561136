import React from 'react'
import { Route, Routes } from "react-router-dom";
import HomePage from '../HomePage/HomePage';
import Login from '../Login/Login';
import Register from '../Login/Register/Register';
import ResetPassWord from '../Login/ResetPassWord/ResetPassWord';
import SetNewPassword from '../Login/SetNewPassword/SetNewPassword';
import CheckEmail from '../Login/CheckEmail/CheckEmail';
import ProductDescription from '../ProductDescription/ProductDescription';
import Payment_checkout from '../payment-checkout-form/Payment_checkout';
import Product_Cart_Inner from '../Product_Cart_Inner/Product_Cart_Inner';
import AboutUs from '../About-us/AboutUs';
import ContactUs from '../contact-us/ContactUs';
import Blog from '../Blog/Blog';
import Faq from '../Faq/Faq';
import BlogDetails from '../Blog/Blog-details/BlogDetails';
import ProductCards from '../Common_Component/ProductCards/ProductCards';
import Category from '../category/Category';

import CategoryProductpage from '../Category-product-page/CategoryProductpage';
import Wishlist from '../Whislist/Wishlist';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import TermsAndCondition from '../PrivacyPolicy/TermsAndCondition';

const AllRoutes = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<HomePage />}></Route>
                <Route path='/category' element={<Category />}></Route>
                <Route path='/login' element={<Login />}></Route>
                <Route path='/register' element={<Register />}></Route>
                <Route path='/resetpassword' element={<ResetPassWord />}></Route>
                <Route path='/setnewpassword' element={<SetNewPassword />}></Route>
                <Route path='/checkemail' element={<CheckEmail />}></Route>
                <Route path='/productdescription' element={<ProductDescription />}></Route>
                <Route path='/checkout-form' element={<Payment_checkout />}></Route>
                <Route path='/product-cart-inner' element={<Product_Cart_Inner />}></Route>
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog-details" element={<BlogDetails />} />
                <Route path="/faq" element={<Faq />} />
                
                <Route path="/cards" element={<ProductCards />} />
                <Route path="/category-product/:id" element={<CategoryProductpage />} />
                <Route path="/wishlist" element={<Wishlist />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-condition" element={<TermsAndCondition />} />
            </Routes>

        </>
    )
}

export default AllRoutes