import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "./ProductCards.css";
import Buttons from "../Buttons/Buttons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heart_Component from "../Heart_Component/Heart_Component";
import { Link } from "react-router-dom";
function ProductCards({ images, cardtitle, imgClassName, prodId, productId }) {
    const [heartactive, setheartActive] = useState(false);
    const handleClickes = ({ data }) => {
        setheartActive(!heartactive);
    };
    return (
        <>
            <section className="ProductCards">

                <div className="Card-Product mb-3">
                    {/* <Heart_Component/> */}
                    <div className="image-card">
                        <img src={images} className={imgClassName} />
                    </div>
                    <div className="card-content">
                        <p className="text-outer-five">{cardtitle}</p>
                        <Link to={`/category-product/${prodId ?? productId}`}>
                            <div className="view-details">
                                <div>
                                    <button className="click-btn btn-style511">
                                        <span>VIEW DETAILS</span>
                                    </button>
                                </div>
                                {/* <Buttons className={"View text-outer-five"} text={"VIEW DETAILS"}></Buttons> */}
                            </div>
                        </Link>

                    </div>
                </div>
            </section>
        </>
    );
}
export default ProductCards;
