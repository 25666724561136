import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Category = async (domain_id, type) => {
  // Corrected function declaration
  try {
    return await getData(
      `/info-website/home/category?domain_id=${domain_id}&type=${type}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const AllSubCategory = async (seller_id, id) => {
  // Corrected function declaration
  try {
    return await getData(
      `/info-website/home/sub-category/${id}?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const AllChildCategory = async (seller_id, id) => {
  // Corrected function declaration
  try {
    return await getData(
      `/info-website/home/child-category/${id}?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const SubCategory = async (id) => {
  try {
    return await getData(`/without-login/allsubcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const singleSubCategory = async (id) => {
  try {
    return await getData(`/without-login/subcategory/${id}`);
  } catch (error) {
    console.log(error);
  }
};
export const ChildSubCategory = async (id) => {
  try {
    return await getData(`/without-login/allchildsubcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const popularCategory = async () => {
  try {
    return await getData(`/without-login/all-pupular-category`);
  } catch (error) {
    console.log(error);
  }
};

export const Products = async (
  s_category_id = "",
  s_sub_category_id = "",
  s_child_category_id = "",
  seller_id,
  domain_id,
  search_name = "",

  // added_by = "BTC"
) => {
  try {
    // added_by=${added_by}&
    let url = `/info-website/home/products?seller_id=${seller_id}&domain_id=${domain_id}`;

    if (s_category_id && s_sub_category_id && s_child_category_id) {
      url += `&s_category_id=${s_category_id}&s_sub_category_id=${s_sub_category_id}&s_child_category_id=${s_child_category_id}`;
    } else if (s_category_id && s_sub_category_id) {
      url += `&s_category_id=${s_category_id}&s_sub_category_id=${s_sub_category_id}`;
    } else if (s_category_id) {
      url += `&s_category_id=${s_category_id}`;
    } else if (s_sub_category_id) {
      url += `&s_sub_category_id=${s_sub_category_id}`;
    } else if (s_child_category_id) {
      url += `&s_child_category_id=${s_child_category_id}`;
    }
    if (search_name) {
      url += `&search_name=${search_name}`;
    }

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const AllProducts = async (
  data,
  seller_id
  // added_by = "BTC"
) => {
  try {
    let url = `/info-website/home/all-products?seller_id=${seller_id}`;

    return await postData(url, data);
  } catch (error) {
    console.log(error);
  }
};

export const getProductDetail = async (id, seller_id) => {
  try {
    let url = `/info-website/home/all-product-details?id=${id}&seller_id=${seller_id}`;

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const getProductMax = async () => {
  try {
    return await getData(`/info-website/home/all-max`);
  } catch (error) {
    console.log(error);
  }
};

// export const getProductDetail = async (id) => {
//   try {
//     return await getData(`/without-login/products/${id}`);
//   } catch (error) {
//     console.log(error);
//   }
// };

export const postReview = async (dada) => {
  try {
    return await postData(`/info-website/order-review`, dada);
  } catch (error) {
    console.log(error);
  }
};

export const getReview = async (id) => {
  try {
    return await getData(`/info-website/order-review?id=${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const productReviewOnlyOnce = async (id, seller_id) => {
  try {
    return await getData(
      `/info-website/order-review/show?id=${id}&seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const PopularProductdetails = async (seller_id) => {
  try {
    return await getData(
      `/info-website/popular/product?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};
