import React, { useContext, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import "./Veggies_Section_Card.css";
// import Common_Button from "../Common_Button/Common_Button";
import { Link } from "react-router-dom";
import Heart_Component from "../Heart_Component/Heart_Component";
import { Context } from "../../../utils/context";

function Veggies_Section_Card({
  child,
  image,
  vegetablename,
  weight,
  price,
  offprice,
  detailsLink,
  product,
}) {
  const [likedProducts, setLikedProducts] = useState(false); // State to track liked products

  const handleLike = () => {
    setLikedProducts(!likedProducts);
  };

  const { IMG_URL } = useContext(Context);



  return (
    <div className="veggies-section mb-5">
      <Card className="veggies " onClick={() => product(
        child?.category_id,
        child?.sub_category_id,
        child?.child_category_id,
      )}>
        <div className="image-main">
          <Link>
            <Card.Img src={IMG_URL + child?.image} className="electronic-img" />
          </Link>
        </div>
        <div className="text-center">
          <p className="rjfhbwejhebferf">{child?.name}</p>
        </div>
      </Card>
    </div>
  );
}

export default Veggies_Section_Card;
