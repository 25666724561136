import React from "react";
import "./Grand_Total_Table.css";
import { Button, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Buttons from "../../Common_Component/Buttons/Buttons";
import { faClipboard, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heading_Holder from "../Heading_Holder/Heading_Holder";

function Grand_Total_Table() {
  return (
    <>
      <div className="Grand_Total_Table ">
        <div className="container">
          <div className="Heading_text_Holder">
            <Heading_Holder
              headingText={
                <>
                  <FontAwesomeIcon
                    className="Cart_icon"
                    icon={faClipboardList}

                  />
                  Order Summary
                </>
              }
            />
          </div>
        </div>
      </div>
      <div className="Grand_Total_Table">
        <div className="grand_total_table">

          <Table responsive>
            <thead>
              <tr className="top-border">
                <th>Total amount</th>
                <td>₹90</td>
              </tr>
            </thead>
            <thead>
              <tr className="top-border">
                <th>Discount</th>
                <td>₹0</td>
              </tr>
            </thead>

            <thead>
              <tr className="top-border">
                <th>Tax</th>
                <td>₹0</td>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>Shipping</th>
                <td>₹0</td>
              </tr>
            </thead>

            <thead>
              <tr className="bottom-border">
                <th className="bold-text">Total</th>
                <td className="bold-text">₹90</td>
              </tr>
            </thead>
          </Table>
        </div>
        <div className="button-holder">
          <Link to={"/checkout-form"}>
            <Buttons
              className="procheckout_btn"
              text={" PROCEED TO CHECKOUT (1)"}
            />

          </Link>
        </div>
      </div>
    </>
  );
}

export default Grand_Total_Table;
