import React, { useState, useContext, useEffect } from 'react'
import BreadcrumComponent from '../../Common_Component/BreadcrumComponent/BreadcrumComponent';
import ProductDeatilsPage from './ProductDeatilsPage';
import FirstVenderPage from '../firstVenderpage/FirstVenderPage';
import { Col, Container, Row } from 'react-bootstrap';
import { Context } from '../../../utils/context';
import { useParams } from 'react-router-dom';
const FirstVender = () => {
    var HtmlToReactParser = require("html-to-react").Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const { IMG_URL, userdata, getData, deleteData, sellerId } = useContext(Context);
    const [product, setproduct] = useState([]);

    const [category, setCategory] = useState("");
    const { id } = useParams();
    console.log(id, "proid");
    const getProductDetails = async () => {
        const res = await getData(`/info-website/home/all-product-details?seller_id=${sellerId}&id=${id}`);
        if (res?.success) {
            setproduct(res?.data)
        }
    }
    useEffect(() => {
        getProductDetails();
    }, [sellerId]);
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "Categories", link: "#" },
        { text: "Product Description", link: "#" },
    ]);

    console.log("product",product)
    return (
        <>
            <section className='first-vendor'>
                <Container>
                    <div className='bred-cumb-vendor'>
                        <div>
                            <BreadcrumComponent items={breadcrumbItems} />
                        </div>
                        <div className='print'>

                        </div>
                    </div>
                    <Row>
                    <Col xl={4} lg={4} md={12} sm={12}></Col>
                    <Col xl={8} lg={8} md={12} sm={12}>  <h1 className='outer-five-sub-heading'>{product?.product_title} ({product?.product_generic_name})</h1></Col>
                    
                    </Row>
                  
                    <Row>
                        <Col xl={4} lg={4} md={12} sm={12}>
                            <ProductDeatilsPage
                                id={id}
                                sellerId={sellerId}
                                IMG_URL={IMG_URL}
                                product={product}
                                setCategory={setCategory} />
                        </Col>
                        <Col xl={8} lg={8} md={12} sm={12}>
                            <FirstVenderPage
                                id={id}
                                sellerId={sellerId}
                                IMG_URL={IMG_URL}
                                product={product}
                                setCategory={setCategory} />
                        </Col>
                    </Row>
                    <hr />
                </Container>
            </section>
        </>
    )
}

export default FirstVender