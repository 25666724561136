import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Success_Modalss from '../../Common_Component/Success_Modal/Success_Modalss'

const Order_review = ({ handleNext, handlePrevious }) => {
    const [modalShow, setModalShow] = useState(false);
    const placeorderfunc = () => {
        setModalShow(true)

        setTimeout(() => {
            setModalShow(false)
        }, 3000)
    }

    return (
        <>
            <section className='stepp-inner-main'>
                <div className='orde-status-dv'>
                    <div className='row'>
                        <div className='col-md-2 col-6 colwidth'>
                            <p>Order Number</p>
                            <p>0005-12122023</p>
                        </div>
                        <div className='col-md-2 col-6 colwidth'>
                            <p>Status</p>
                            <p>Ordered</p>
                        </div>
                        <div className='col-md-2 col-6 colwidth'>
                            <p>Date</p>
                            <p>5. APR. 2024</p>
                        </div>
                        <div className='col-md-2 col-6 colwidth'>
                            <p>Total</p>
                            <p>₹. 1,234.00</p>
                        </div>
                        <div className='col-md-2 col-6 colwidth'>
                            <p>Payment method</p>
                            <p>Online</p>
                        </div>
                    </div>
                </div>

                <div className='orderr-dettt'>
                    <h6 className='jhber'>Order Details</h6>


                    <div className='detailssss-flex'>
                        <div className='img-div'>
                            <img className='userimgg'  src={
                          process.env.PUBLIC_URL +
                          "/assets/category/m4.png"
                        } />
                        </div>
                        <div className='contdivvv'>
                            <p className='name'>Vivo S24 JPorter</p>
                            <p className='prodrsvd'>Vivo Stylish Sleem</p>
                            <p className='price'>₹ 1,234.00  <span>₹ 1,234.00</span></p>
                            <p className='sizeeepp'>SIZE</p>
                            <p className='sizenumm'>38</p>
                        </div>
                    </div>



                    <div className='totalprieee'>
                        <div className='row mb-4'>
                            <div className='col-lg-10 col-6'>
                                <h6>Shipping & Handling (Flat Rate)</h6>
                            </div>
                            <div className='col-lg-1 col-6'>
                                <h6>Flat Rate</h6>
                            </div>
                            <div className='col-lg-1 col-6'>
                                <h6>₹. 00.00</h6>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-lg-10 col-6'>
                                <h6>Payment Method</h6>
                            </div>
                            <div className='col-1'>
                                <h6>Online</h6>
                            </div>

                        </div>
                        <div className='row totalbg mb-4'>
                            <div className='col-lg-10 col-6'>
                                <h6>Total</h6>
                            </div>
                            <div className='col-lg-1 col-6'>
                                <h6></h6>
                            </div>
                            <div className='col-lg-1 col-6'>
                                <h6>₹. 1,234.00</h6>
                            </div>
                        </div>


                        <div className='row mb-4'>
                            <h6>Address</h6>
                            <hr></hr>
                            <p className='finaladddd'>E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703</p>
                        </div>
                    </div>





                </div>




                <div className="save-btn-div mt-5">
                    <button type='button' className="back-btn" onClick={handlePrevious}>Back</button>
                    <button type='button' className="continue-btn" onClick={placeorderfunc}> PLACE ORDER </button>
                </div>

                <Success_Modalss texts={"Your order has been placed successfully!"} show={modalShow}
                    onHide={() => setModalShow(false)} />
            </section>
        </>
    )
}

export default Order_review