import React from "react";
import Lottie from "react-lottie";
import "../ErrorAnimation/ErrorLottie.css";
import * as animationData from "../ErrorAnimation/ErrorLottie.json";
function ErrorLottie() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <section className="main">
        <div className="vdo-btn">
          <Lottie className="success-lottie me-auto" options={defaultOptions} />
        </div>
      </section>
    </>
  );
}

export default ErrorLottie;
