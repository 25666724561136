import React, { useContext, useState, useEffect } from "react";
import "./Footer.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Buttons from "../Common_Component/Buttons/Buttons";
import { GetAppSetup, GetWebHeader, GetSocialMedia } from "../../utils/apis/master/Master";
import { Context } from "../../utils/context";
const Footer = () => {


  const { IMG_URL, sellerId, sellerDomain } = useContext(Context);
  const [headers, setHeaders] = useState([]);
  const getHeader = async () => {
    const res = await GetWebHeader(sellerId);
    if (res?.success) {
      setHeaders(res?.data);
    }
  };

  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerDomain);
    if (res?.success) {
      setAppSetup(res?.data);
    }
  };

  const [socialMedia, setSocialMedia] = useState();
  const getSocialLink = async () => {
    const res = await GetSocialMedia(sellerDomain);
    if (res?.success) {
      setSocialMedia(res?.data);
    }
  };

  useEffect(() => {
    getHeader();
    getSocialLink();
    getAppSetup();
  }, [sellerId, sellerDomain]);
  return (
    <>
      <section className="footer" style={{ backgroundColor: appSetup?.footer_color }}>
        <Container>
          {/* subscribe-banner-start */}
          <div className='footer-banner'>
            <div className="footer-bannermain">
              <div className="footer-imgdiv">
                <img
                  className="footerimg"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/footer/individual.png"
                  }
                  alt="Logo"
                />
              </div>
              <div className="ban-contentmain">
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-9 col-sm-12 ">
                    <div className="banconts">
                      <h4 className="newssetl" >
                        Subscribe to our newsletter to get updates on our latest
                        collections for you
                      </h4>
                      <p className="getss mb-3" >Get Special deals with our subscription</p>
                      <div className="email-box">
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Enter you mail id for Newsletter"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <InputGroup.Text id="basic-addon2">
                            <Buttons
                              text=" Subscribe"
                              className='subscribe-btn'
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* subscribe-banner-end */}
          <div className='row'>
            <div className='col-lg-3  '>
              <Link to={"/"}>
                <div className="text-lg-center text-md-start text-sm-start text-start">
                  <img className="header-logo" src={IMG_URL +
                    appSetup?.image
                  }
                    alt="Logo"
                  />
                </div>
              </Link>

            </div>
            <div className="col-lg-3  ">
              <div className="text-holder">
                <h2 style={{ color: appSetup?.footer_font_color }}>Useful Links</h2>
              </div>
              <Nav
                defaultActiveKey="/"
                className="d-flex-none"
              >
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/faq" style={{ color: appSetup?.footer_font_color }}>
                    FAQs
                  </NavLink>
                </Nav.Item>


                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/contact-us" style={{ color: appSetup?.footer_font_color }}>
                    CONTACT US
                  </NavLink>
                </Nav.Item>

                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/privacy-policy" style={{ color: appSetup?.footer_font_color }}>
                    PRIVACY POLICY
                  </NavLink>
                </Nav.Item>

                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/terms-condition" style={{ color: appSetup?.footer_font_color }}>
                    TERMS & CONDITION
                  </NavLink>
                </Nav.Item>
              </Nav>
            </div>

            <div className="col-lg-3 ">
              <div className="text-holder">
                <h2 style={{ color: appSetup?.footer_font_color }}>Address</h2>
              </div>
              <div className="call-holder my-lg-3 my-md-3  my-sm-2 my-2 ">
                <FontAwesomeIcon
                  icon="fa-solid fa-phone"
                  className="call-icon me-2"
                  style={{ color: appSetup?.footer_font_color }}
                />{" "}
                <span className="me-2" style={{ color: appSetup?.footer_font_color }}>{appSetup?.contact_no}</span>
              </div>
              <div className="call-holder my-lg-3 my-md-3 my-sm-2 my-2 ">
                <FontAwesomeIcon
                  icon="fa-solid fa-envelope"
                  className="call-icon me-2"
                  style={{ color: appSetup?.footer_font_color }}
                />
                <span style={{ color: appSetup?.footer_font_color }}>{appSetup?.email}</span>
              </div>
              <div className="address-holder my-3">
                <p style={{ color: appSetup?.footer_font_color }}>{appSetup?.address}</p>
              </div>
            </div>



            <div className="col-lg-3">
              <div className="text-holder">
                <h2 style={{ color: appSetup?.footer_font_color }}>Social Link</h2>
              </div>
              <div className="social-icon my-lg-3 my-md-3 my-sm-2 my-2 ">
                {/* <img
                  className="sociallogo"
                  src={
                    process.env.PUBLIC_URL + "/assets/Icon/facebook.png"
                  }
                  alt="sociallogo"
                /> */}
                {socialMedia?.map((val) => (
                  <a target="_black" href={`${val?.link ? val.link : '#'}`}> {/* If val.link is not available, fallback to '#' */}

                    <div className="footer-soc-logo-holder">

                      <img
                        className="sociallogo"
                        src={IMG_URL + val?.image}
                        alt="sociallogo"
                      />
                    </div>

                  </a>


                ))}
                {/* <img
                  className="sociallogo"
                  src={
                    process.env.PUBLIC_URL + "/assets/Icon/linkdin.png"
                  }
                  alt="sociallogo"
                />
                <img
                  className="sociallogo"
                  src={
                    process.env.PUBLIC_URL + "/assets/Icon/twitter.png"
                  }
                  alt="sociallogo"
                />
                <img
                  className="sociallogo"
                  src={
                    process.env.PUBLIC_URL + "/assets/Icon/youtube.png"
                  }
                  alt="sociallogo"
                /> */}
              </div>
            </div>
            <div className="col-12">

            </div>
            <div className="col-md-12">
              <div className="copyright-holder text-center">
                <p style={{ color: appSetup?.footer_font_color }}>
                  ©NETPURTI. All Rights Reserved (Terms of Use)

                  <Link to="https://profcymaglobal.com/" target="___blank" style={{ color: appSetup?.footer_font_color }}>
                    Developed and Managed by Profcyma.
                  </Link>{" "}
                </p>
              </div>
            </div>
            {/* <div className="col-md-6 col-sm-4">
              <Link to={"/"}>
                <div className="text-md-end text-sm-end text-start">
                  <img className="header-logo2" src={process.env.PUBLIC_URL + "/assets/Websitelogo/netpurti_logo.png"
                  }
                    alt="Logo"
                  />
                </div>
              </Link>
            </div> */}
          </div>
        </Container>
      </section>
    </>
  );
};

export default Footer;