import React, { useState, useEffect, useContext } from "react";
import "./Motivation.css";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Context } from "../../../utils/context";
import { motivation } from "../../../utils/apis/common/Common";
// Import Swiper styles
import { Autoplay } from 'swiper/modules';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { Row } from "react-bootstrap";

function Motivation() {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const [getMotivation, setMotivation] = useState();
  const { getData, IMG_URL, sellerId, sellerDomain } = useContext(Context);
  const getProject = async (data) => {
    const res = await motivation(sellerDomain);
    if (res?.success) {
      setMotivation(res?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  useEffect(() => {
    getProject();
  }, [sellerDomain]);
  const slidesData = [
    {
      imgSrc: "/assets/Homepage/Motivation/person1.png",
      text: "I purchased a copper water bottle & am happy with the product easy to handle, carry and size is perfectly comfortable. Once you will buy this I am sure you water intake also enhance especially from this vessel.",
      name: "aditi"
    },
    {
      imgSrc: "assets/Homepage/Motivation/person2.png",
      text: "I purchased a copper water bottle & am happy with the product easy to handle, carry and size is perfectly comfortable. Once you will buy this I am sure you water intake also enhance especially from this vessel.",
      name: "preeti"
    },
    {
      imgSrc: "/assets/Homepage/Motivation/centerperson.png",
      text: "I purchased a copper water bottle & am happy with the product easy to handle, carry and size is perfectly comfortable. Once you will buy this I am sure you water intake also enhance especially from this vessel.",
      name: "Sakshi"
    },
    {
      imgSrc: "/assets/Homepage/Motivation/person1.png",
      text: "I purchased a copper water bottle & am happy with the product easy to handle, carry and size is perfectly comfortable. Once you will buy this I am sure you water intake also enhance especially from this vessel.",
      name: "Smita"
    },
    {
      imgSrc: "/assets/Homepage/Motivation/person1.png",
      text: "I purchased a copper water bottle & am happy with the product easy to handle, carry and size is perfectly comfortable. Once you will buy this I am sure you water intake also enhance especially from this vessel.",
      name: "aditya"
    },
    // Add more slide data objects as needed
  ];
  return (
    <>
      <section className="home-swiper">
        <div className="container">
          <div className='common-title-div'>
            <h1 className="heading-Outer-five">Motivation From Our Clients</h1>
          </div>
          <Row className="desktop-view-slider">
            <Swiper
              effect={"coverflow"}
              loop={true}
              grabCursor={true}
              centeredSlides={true}
              initialSlide={2}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 300,
                modifier: 1,
                slideShadows: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,

                  initialSlide: 4
                },
                321: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                768: { slidesPerView: 1 },
                992: { slidesPerView: 3.5 },
                1200: { slidesPerView: 3.5 },
              }}


              autoplay={{
                delay: 22222500,
                disableOnInteraction: false,
              }}

              spaceBetween={30}
              pagination={true}
              modules={[EffectCoverflow, Pagination, Autoplay]}
              className="mySwiper"
            >
              {getMotivation?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="main-card ">
                    <div className="row">
                      <div className="col-xxl-4 col-xl-4 col-md-4 col-sm-4 col-12">
                        <div className="main-img-div">
                          <div className="left-active-border"></div>
                          <div className="top-active-border"></div>
                          <div className="bottom-active-border"></div>

                          <img
                            className="about-img"
                            src={IMG_URL + slide?.image}
                            alt="Logo"
                          />
                        </div>

                      </div>
                      <div className="col-xxl-8 col-xl-8 col-md-8 col-sm-6 col-12 ">
                        <div className="content">
                          <p className="text-outer-five">{htmlToReactParser.parse(slide?.description)}</p>
                          <h1 className="text-outer-five">{slide?.name}</h1>
                        </div>

                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Row>



          <Row className="mobile-view-slider mt-4">
            <Swiper
              loop={true}
              breakpoints={{
                0: { slidesPerView: 1, },
                321: { slidesPerView: 1, spaceBetween: 40, },
                768: { slidesPerView: 2 },
                992: { slidesPerView: 2 },
                1200: { slidesPerView: 3.5 },
              }}
              spaceBetween={30}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination]}
              className="mySwiper2"
            >
              {getMotivation?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="card mb-5">
                    <div className="main-card1 text-center">
                      <img className="about-img1" src={IMG_URL + slide?.image} alt="Logo" />
                    </div>
                    <div className="content1 mt-3">
                      <p className="text-outer-five1 text-center">{htmlToReactParser.parse(slide?.description)}</p>
                      <h1 className="text-outer-five2 text-center">{slide?.name}</h1>
                    </div>

                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Row>
        </div>
      </section>
    </>
  );
}

export default Motivation;