import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const postContactUs = async (data) => {
  try {
    return await postData(`/info-website/contact-us`, data);
  } catch (error) {
    console.log(error);
  }
};



export const faqPage = async (domain_id, type) => {
  try {
    return await getData(`/info-website/faq/faq?domain_id=${domain_id}?type=${type}`);
  } catch (error) {
    console.log(error);
  }
}



export const aboutBanner = async (domain_id) => {
  try {
    return await getData(`/info-website/about-us/banner?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
}

export const allourstory = async (domain_id) => {
  try {
    return await getData(`/info-website/about-us/our-story?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
}

export const getwhychooseus = async (domain_id) => {
  try {
    return await getData(`/info-website/about-us/why-choose?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
}

export const getHappyCustomers = async (seller_id) => {
  try {
    return await getData(`/info-website/about-us/happy-customer?seller_id=${seller_id}`);
  } catch (error) {
    console.log(error);
  }
}

export const homeBanner = async (domain_id) => {
  try {
    return await getData(`/info-website/home/all-home-banner?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
}

export const allCategory = async (domain_id, type) => {
  try {
    return await getData(`/info-website/home/category?domain_id=${domain_id}&type=${type}`);
  } catch (error) {
    console.log(error);
  }
}

export const trendingProducts = async (domain_id) => {
  try {
    return await getData(`/info-website/popular/product?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
}

export const afterTrendingProducts = async (domain_id) => {
  try {
    return await getData(`/info-website/popular/product?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
}

export const categoryBanner = async (domain_id) => {
  try {
    return await getData(`/info-website/home/all-category-banner?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
}

export const saleBanner = async (domain_id) => {
  try {
    return await getData(`/info-website/home/all-sale-banner?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
}

export const motivation = async (domain_id) => {
  try {
    return await getData(`/info-website/home/all-user-motivation?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
}

export const PrivacyPolicyData = async (seller_id, type) => {
  try {
    return await getData(
      `/info-website/policy/privacy-policy?seller_id=${seller_id}&type=${type}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const TermConditionData = async (seller_id, type) => {
  try {
    return await getData(
      `/info-website/policy/term-condition?seller_id=${seller_id}&type=${type}`
    );
  } catch (error) {
    console.log(error);
  }
};