import React from "react";
import Heading_Holder from "../../Common_Component/Heading_Holder/Heading_Holder";
import Product_Swiper from "../../Common_Component/Product_Swiper/Product_Swiper";
import "./SimilarPatterns.css"
const SimilarPatterns = ({ heading }) => {
  const sliderMenImages = [
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
      // detailsLink: "./productdescription",
      subheading: "Peter England -",
      spanText: " Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
      subheading: "Allen Solly -",
      spanText: "Semi Formal Shirt",
      productDescription: "Men Blue Solid Casual Jacket",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
      subheading: "Van Heusen - ",
      spanText: "Semi Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Formal Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },

    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Shirt",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Full Sleeves Formal Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
      subheading: "Peter England -",
      spanText: " Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
  ];
  return (
    <>
      <section className="SimilarPattern">
        <div className="container">
          <Heading_Holder
            headingText={heading}
            className="heading-holder"
          />

          <Product_Swiper
            sliderImages={sliderMenImages}
            slidesPerView={4}
            breakpoints={{
              0: { slidesPerView: 2 },
              380: { slidesPerView: 2 },
              485: { slidesPerView: 2 },
              575: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              992: { slidesPerView: 4 },
              1024: { slidesPerView: 4 },
              1200: { slidesPerView: 4 },
              1440: { slidesPerView: 4 },
              2000: { slidesPerView: 4 },
            }}
          />
        </div>
      </section>







    </>
  );
};

export default SimilarPatterns;
