import { React, useState, useRef, useEffect, useContext } from "react";
import './ProductDetailsPage.css'
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";
import { Autoplay, Navigation } from "swiper/modules";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { getProductDetail } from "../../../utils/apis/Product/Product";
import {
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCartShopping,
    faLock,
} from "@fortawesome/free-solid-svg-icons";

import "swiper/css";
import "swiper/css/free-mode";
// import "swiper/css/navigation";
import "swiper/css/thumbs";
import {
    AddToCart,
    RemoveToCart,
    getCartCheck,
} from "../../../utils/apis/addToCart/addToCart";

import { Context } from "../../../utils/context";
const ProductDeatilsPage = ({ id, sellerId, IMG_URL, setCategory }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const [modalShow, setModalShow] = useState(false);
    const swiperRef = useRef(null);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [swiperInstancemobile, setSwiperInstancemobile] = useState(null);
    const [swiperInstancenew, setSwiperInstancenew] = useState(null);
    const [showAddedToCart, setShowAddedToCart] = useState(false);

    const { signin,dynamicImages } = useContext(Context);
    const navigate = useNavigate();
    const handleAddToCart = async (type = null) => {
        const data = {
            product_id: id,
            seller_id: sellerId,
            s_p_v_a_d_id: getValues("s_p_v_a_d_id"),
            s_p_v_a_one_d_a_d_id: getValues("s_p_v_a_one_d_a_d_id"),
            s_p_v_a_o_d_a_d_a_d_id: getValues("s_p_v_a_o_d_a_d_a_d_id"),
        };

        await AddToCart(data);
        setShowAddedToCart(true);
        getCartCheckProduct();

        setTimeout(() => {
            setShowAddedToCart(false);
        }, 3000);
    };

    const BuyNowClick = async () => {
        const data = {
            product_id: id,
            seller_id: sellerId,
            s_p_v_a_d_id: getValues("s_p_v_a_d_id"),
            s_p_v_a_one_d_a_d_id: getValues("s_p_v_a_one_d_a_d_id"),
            s_p_v_a_o_d_a_d_a_d_id: getValues("s_p_v_a_o_d_a_d_a_d_id"),
        };

        if (!checkExits) {
            await AddToCart(data);
            setShowAddedToCart(true);
            getCartCheckProduct();
        }
        navigate("/product-cart-inner");
    };

    const handleRemoveFromCart = async () => {
        const res = await RemoveToCart(checkExits);
        getCartCheckProduct();
    };

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const handleSliderClick = (direction) => {
        if (swiperInstancenew) {
            if (direction === "prev") {
                swiperInstancenew.slidePrev();
            } else if (direction === "next") {
                swiperInstancenew.slideNext();
            }
        }
    };

    const handleSliderClickMobile = (direction) => {
        if (swiperInstancemobile) {
            if (direction === "prev") {
                swiperInstancemobile.slidePrev();
            } else if (direction === "next") {
                swiperInstancemobile.slideNext();
            }
        }
    };

    const breadcrumbItems = [
        { text: "Home", link: "/" },
        { text: "New Arrivals", link: "/" },
        { text: "Formal Shits", link: "", className: "active" },
    ];

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        reset,
        formState: { errors },
    } = useForm();

    const [images, setImages] = useState([]);

    const [singleVariant, setSingleVariant] = useState(false);
    const [MultipleVariantTwo, setMultipleVariantTwo] = useState(false);
    const [MultipleVariantThree, setMultipleVariantThree] = useState(false);

    const [data, setData] = useState({});
    const [checkExits, setCheckExits] = useState(false);

    const getProductData = async (id) => {
        {
            const res = await getProductDetail(id, sellerId);
            if (res?.success) {
                setData(res.data);
                setCategory(res?.data?.s_category_id);

                if (res?.data?.s_product_variant?.variant_type === "Single") {
                    setData(res.data);
                    setValue("product_title", res.data?.product_title);
                    setValue(
                        "customization",
                        res.data?.s_product_customization?.s_customization?.name
                    );
                    setValue(
                        "variant",
                        res.data?.s_product_variant?.s_product_variant_attribute?.s_attribute
                            ?.name
                    );
                    setValue(
                        "variant_details",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details
                    );

                    setValue(
                        "sku_id",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.sku_id
                    );

                    setValue(
                        "s_p_v_a_d_id",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.id
                    );

                    setValue(
                        "mrp",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
                            ?.mrp
                    );
                    setValue(
                        "selling_price",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
                            ?.selling_price
                    );

                    setSingleVariant(true);
                    setImages(
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_images
                    );
                } else if (res?.data?.s_product_variant?.variant_type === "Multiple") {
                    if (
                        res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                            ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_as.length == 0
                    ) {
                        setData(res.data);
                        setValue("product_title", res.data?.product_title);
                        setValue(
                            "customization",
                            res.data?.s_product_customization?.s_customization?.name
                        );
                        setValue(
                            "variant",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
                        );
                        setValue(
                            "variant_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
                        );

                        setValue(
                            "variant_one",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
                        );

                        setValue(
                            "variant_one_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
                        );

                        setValue(
                            "sku_id",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.sku_id
                        );
                        setValue(
                            "s_p_v_a_one_d_a_d_id",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.id
                        );
                        setValue(
                            "mrp",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                        );
                        setValue(
                            "selling_price",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
                        );
                        setMultipleVariantTwo(true);
                        setImages(
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_a_d_images
                        );
                    } else {
                        setData(res.data);
                        setValue("product_title", res.data?.product_title);
                        setValue(
                            "customization",
                            res.data?.s_product_customization?.s_customization?.name
                        );
                        setValue(
                            "variant",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
                        );
                        setValue(
                            "variant_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
                        );

                        setValue(
                            "variant_one",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
                        );

                        setValue(
                            "variant_one_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
                        );

                        setValue(
                            "variant_two",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
                        );

                        setValue(
                            "variant_two_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds
                        );

                        setValue(
                            "sku_id",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
                        );
                        setValue(
                            "s_p_v_a_o_d_a_d_a_d_id",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
                        );
                        setValue(
                            "mrp",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                        );

                        setValue(
                            "selling_price",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
                        );

                        setImages(
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_a_d_images
                        );
                        setMultipleVariantThree(true);
                    }
                }
            }

        }
    };

    const getCartCheckProduct = async () => {
        const res = await getCartCheck(id, sellerId);
        if (res?.success) {
            setCheckExits(res.data.id);
        } else {
            setCheckExits(res?.success);
        }
    };

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };

    const onChangeVariant = async (data, type = "Single", level = "1") => {
        if (type === "Single" && level === "1") {
            setValue("sku_id", data?.sku_id);
            setValue("mrp", data?.s_p_v_a_d_price_a_stock?.mrp);
            setValue("selling_price", data?.s_p_v_a_d_price_a_stock?.selling_price);
            setValue("s_p_v_a_d_id", data?.id);
        } else if (type === "Multiple" && level === "2") {
            setValue("sku_id", data?.sku_id);
            setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
            setValue(
                "selling_price",
                data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
            );
            setValue("s_p_v_a_one_d_a_d_id", data?.id);
        } else if (type === "Multiple" && level === "3") {
            setValue("sku_id", data?.sku_id);
            setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
            setValue(
                "selling_price",
                data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
            );
            setValue("s_p_v_a_o_d_a_d_a_d_id", data?.id);
        }
    };

    useEffect(() => {
        if (id) {
            getProductData(id);
            if (signin) {
                getCartCheckProduct();
            }

        }
    }, [id]);
    return (
        <>
            <section className="cctv-innerpage">
                <div className="container">

                    <div className="row camera-sec">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 ">
                            <div className="card-sec ">
                                {/* <Heart_Component main image vertical swiper/> */}
                                <Link to="">
                                    <div className="product-detail text-center">
                                        {selectedImage ? (
                                            <img
                                                className="product-img"
                                                src={selectedImage}
                                                alt="Selected"
                                            />
                                        ) : (
                                            <img
                                                className="product-img"
                                                src={
                                                    IMG_URL + dynamicImages[0]?.image
                                                }
                                                alt="Default"
                                            />
                                        )}
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="camera-slider">
                                {/* slider section start horizontal swiper*/}
                                <Swiper
                                    slidesPerView={"auto"}
                                    modules={[Autoplay]}
                                    spaceBetween={20}
                                    breakpoints={{
                                        0: { slidesPerView: 3, spaceBetween: 10 },
                                        380: { slidesPerView: 3 },
                                        485: { slidesPerView: 3 },
                                        575: { slidesPerView: 3 },
                                        768: { slidesPerView: 5 },
                                        992: { slidesPerView: 3 },
                                        1024: { slidesPerView: 3 },
                                        1200: { slidesPerView: 4 },
                                        1440: { slidesPerView: 4 },
                                        2000: { slidesPerView: 4 },
                                    }}
                                    navigation={{ clickable: false }}
                                    pagination={{ clickable: true }}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                        pauseOnMouseEnter: true,
                                        loop: true,
                                    }}
                                    className="mySwiper"

                                >
                                    {dynamicImages?.map((imageSrc, index) => (
                                        <SwiperSlide key={index}>
                                            <div className='camera-background'>
                                                <div
                                                    className="camera-imgs"
                                                    onClick={() =>
                                                        handleImageClick(IMG_URL + imageSrc?.image)
                                                    }
                                                >
                                                    <img
                                                        src={IMG_URL + imageSrc?.image}
                                                        className="inner-img"
                                                    />
                                                </div>
                                            </div>

                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div
                                    className="main-slider-cctv"
                                    onClick={() => handleMainSliderClick("prev")}
                                >
                                    <div className="arrow-back" />
                                </div>
                                <div
                                    className="main-slider-cctv"
                                    onClick={() => handleMainSliderClick("next")}
                                >
                                    <div className="arrow-next" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductDeatilsPage