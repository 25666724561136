import React, { useState } from "react";
import "./Product_Details_Div.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faCircle } from "@fortawesome/free-solid-svg-icons";
import Incre_Decre_btn from "../Incre_Decre_btn/Incre_Decre_btn";

function Product_Details_Div({
  circleColor,
  spanText,
  subheading,
  productDescription,
  Price,
  subPrice,
  ColorCartHolder
}) {
  const [showIncreDecreBtn, setShowIncreDecreBtn] = useState(false);
  const circleStyle = {
    color: circleColor,
  };

  // Example data for circle colors/icons
  const circleData = ["red", "blue", "green", "yellow"];

  const handleCartClick = () => {
    setShowIncreDecreBtn(true);
  };

  return (
    <>
      <div className="Product_Details_Div">
      {ColorCartHolder}
        {/* <div className="color_cart_holder">
          <div className="color_option_holder">
            {circleData.map((color, index) => (
              <FontAwesomeIcon
                key={index}
                className="circleClassName"
                style={{ ...circleStyle, color }}
                icon={faCircle}
              />
            ))}
          </div>

          <div className="Cart_Holder">
            {!showIncreDecreBtn && (
              <FontAwesomeIcon
                className="CartClassName"
                icon={faCartShopping}
                onClick={handleCartClick}
              />
            )}
            {showIncreDecreBtn && <Incre_Decre_btn />}
          </div>
        </div> */}

        <div className="text-holder">
          <h1 className="subheading">
            {subheading} <span className="spanText">{spanText}</span>
          </h1>
          <p className="subText">{productDescription}</p>
        </div>

        <div className="price_holder">
          <h1 className="spanText me-3 mb-0">{Price}</h1>
          <p className="label_text  subPrice ">{subPrice}</p>
        </div>
      </div>
    </>
  );
}

export default Product_Details_Div;
