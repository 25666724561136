import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useEffect, useContext } from "react";
import { Button, Col, ProgressBar, Row } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import { Autoplay, Navigation } from "swiper/modules";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { getProductDetail } from "../../../utils/apis/Product/Product";
import "./FirstVenderPage.css";
// import Button from "../../Common_Component/Buttons/Buttons";
import {
    AddToCart,
    RemoveToCart,
    getCartCheck,
} from "../../../utils/apis/addToCart/addToCart";
import { useParams } from "react-router-dom";
import { Context } from "../../../utils/context";
const FirstVenderPage = ({ product }) => {
    var HtmlToReactParser = require("html-to-react").Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const { IMG_URL, userdata, getData, deleteData, sellerId } = useContext(Context);
    // const [product, setproduct] = useState([]);

    const [category, setCategory] = useState("");
    const { id } = useParams();
    console.log(id, "proid");
    // const getProductDetails = async () => {
    //     const res = await getData(`/info-website/home/all-product-details?seller_id=${sellerId}&id=${id}`);
    //     if (res?.success) {
    //         setproduct(res?.data)
    //     }
    // }
    const [selectedImage, setSelectedImage] = useState(null);

    const [modalShow, setModalShow] = useState(false);
    const swiperRef = useRef(null);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [swiperInstancemobile, setSwiperInstancemobile] = useState(null);
    const [swiperInstancenew, setSwiperInstancenew] = useState(null);
    const [showAddedToCart, setShowAddedToCart] = useState(false);
    const [selectedButton, setselectedButton] = useState();
    const [selectedButton2, setselectedButton2] = useState();
    const [selectedButton3, setselectedButton3] = useState();

    const { signin, setDynamicImages } = useContext(Context);
    const navigate = useNavigate();
    const handleAddToCart = async (type = null) => {
        const data = {
            product_id: id,
            seller_id: sellerId,
            s_p_v_a_d_id: getValues("s_p_v_a_d_id"),
            s_p_v_a_one_d_a_d_id: getValues("s_p_v_a_one_d_a_d_id"),
            s_p_v_a_o_d_a_d_a_d_id: getValues("s_p_v_a_o_d_a_d_a_d_id"),
        };

        await AddToCart(data);
        setShowAddedToCart(true);
        getCartCheckProduct();

        setTimeout(() => {
            setShowAddedToCart(false);
        }, 3000);
    };

    const BuyNowClick = async () => {
        const data = {
            product_id: id,
            seller_id: sellerId,
            s_p_v_a_d_id: getValues("s_p_v_a_d_id"),
            s_p_v_a_one_d_a_d_id: getValues("s_p_v_a_one_d_a_d_id"),
            s_p_v_a_o_d_a_d_a_d_id: getValues("s_p_v_a_o_d_a_d_a_d_id"),
        };

        if (!checkExits) {
            await AddToCart(data);
            setShowAddedToCart(true);
            getCartCheckProduct();
        }
        navigate("/product-cart-inner");
    };

    const handleRemoveFromCart = async () => {
        const res = await RemoveToCart(checkExits);
        getCartCheckProduct();
    };

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const handleSliderClick = (direction) => {
        if (swiperInstancenew) {
            if (direction === "prev") {
                swiperInstancenew.slidePrev();
            } else if (direction === "next") {
                swiperInstancenew.slideNext();
            }
        }
    };

    const handleSliderClickMobile = (direction) => {
        if (swiperInstancemobile) {
            if (direction === "prev") {
                swiperInstancemobile.slidePrev();
            } else if (direction === "next") {
                swiperInstancemobile.slideNext();
            }
        }
    };

    const breadcrumbItems = [
        { text: "Home", link: "/" },
        { text: "New Arrivals", link: "/" },
        { text: "Formal Shits", link: "", className: "active" },
    ];

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        reset,
        formState: { errors },
    } = useForm();

    const [images, setImages] = useState([]);

    const [singleVariant, setSingleVariant] = useState(false);
    const [MultipleVariantTwo, setMultipleVariantTwo] = useState(false);
    const [MultipleVariantThree, setMultipleVariantThree] = useState(false);

    const [data, setData] = useState({});
    const [checkExits, setCheckExits] = useState(false);

    const getProductData = async (id) => {
        {
            const res = await getProductDetail(id, sellerId);
            if (res?.success) {
                setData(res.data);
                setCategory(res.data?.s_category_id);

                if (res?.data?.s_product_variant?.variant_type === "Single") {
                    setData(res.data);
                    setValue("product_title", res.data?.product_title);
                    setValue(
                        "customization",
                        res.data?.s_product_customization?.s_customization?.name
                    );
                    setValue(
                        "variant",
                        res.data?.s_product_variant?.s_product_variant_attribute?.s_attribute
                            ?.name
                    );
                    setValue(
                        "variant_details",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details
                    );

                    setValue(
                        "sku_id",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.sku_id
                    );

                    setValue(
                        "s_p_v_a_d_id",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.id
                    );

                    setValue(
                        "mrp",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
                            ?.mrp
                    );
                    setValue(
                        "selling_price",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock
                            ?.selling_price
                    );

                    setSingleVariant(true);
                    setImages(
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_images
                    );

                    setDynamicImages(
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_images
                    );

                } else if (res?.data?.s_product_variant?.variant_type === "Multiple") {
                    if (
                        res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                            ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_as.length == 0
                    ) {
                        setData(res.data);
                        setValue("product_title", res.data?.product_title);
                        setValue(
                            "customization",
                            res.data?.s_product_customization?.s_customization?.name
                        );
                        setValue(
                            "variant",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
                        );
                        setValue(
                            "variant_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
                        );

                        setValue(
                            "variant_one",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
                        );

                        setValue(
                            "variant_one_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
                        );

                        setValue(
                            "sku_id",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.sku_id
                        );
                        setValue(
                            "s_p_v_a_one_d_a_d_id",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.id
                        );
                        setValue(
                            "mrp",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                        );
                        setValue(
                            "selling_price",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
                        );
                        setMultipleVariantTwo(true);
                        setImages(
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_a_d_images
                        );

                        setDynamicImages(res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                            ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_a_d_images);

                    } else {
                        setData(res.data);
                        setValue("product_title", res.data?.product_title);
                        setValue(
                            "customization",
                            res.data?.s_product_customization?.s_customization?.name
                        );
                        setValue(
                            "variant",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
                        );
                        setValue(
                            "variant_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
                        );

                        setValue(
                            "variant_one",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
                        );

                        setValue(
                            "variant_one_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
                        );

                        setValue(
                            "variant_two",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
                        );

                        setValue(
                            "variant_two_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds
                        );

                        setValue(
                            "sku_id",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
                        );
                        setValue(
                            "s_p_v_a_o_d_a_d_a_d_id",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
                        );
                        setValue(
                            "mrp",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                        );

                        setValue(
                            "selling_price",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
                        );

                        setImages(
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                                ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_a_d_images
                        );

                        setDynamicImages(res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
                            ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                            ?.s_p_v_a_one_d_a_d_a_d_images);

                        setMultipleVariantThree(true);
                    }
                }
            }

        }
    };

    const getCartCheckProduct = async () => {
        const res = await getCartCheck(id, sellerId);
        if (res?.success) {
            setCheckExits(res.data.id);
        } else {
            setCheckExits(res?.success);
        }
    };

    console.log(getValues("variant_details"));
    const onChangeVariant = async (data, type = "Single", level = "1") => {
        if (type === "Single" && level === "1") {
            setValue("sku_id", data?.sku_id);
            setValue("mrp", data?.s_p_v_a_d_price_a_stock?.mrp);
            setValue("selling_price", data?.s_p_v_a_d_price_a_stock?.selling_price);
            setValue("s_p_v_a_d_id", data?.id);
        } else if (type === "Multiple" && level === "2") {
            setValue("sku_id", data?.sku_id);
            setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
            setValue(
                "selling_price",
                data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
            );
            setValue("s_p_v_a_one_d_a_d_id", data?.id);
        } else if (type === "Multiple" && level === "3") {
            setValue("sku_id", data?.sku_id);
            setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
            setValue(
                "selling_price",
                data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
            );
            setValue("s_p_v_a_o_d_a_d_a_d_id", data?.id);
        }
    };

    useEffect(() => {
        if (id) {
            getProductData(id);
            // if (signin) {
            //     getCartCheckProduct();
            // }
        }
    }, [id]);


    useEffect(() => {

        if (getValues("variant_details")) {
            const variant_details = getValues("variant_details");
            const buttonName =
                `${variant_details?.[0]?.s_sub_attribute?.name ?? "Unknown"}`
            setselectedButton(buttonName?.trim())
        }

        if (getValues("variant_one_details")) {
            const variant_details2 = getValues("variant_one_details");
            const buttonName =
                `${variant_details2?.[0]?.s_sub_attribute?.name ?? "Unknown"}`
            setselectedButton2(buttonName?.trim())
        }

        if (getValues("variant_two_details")) {
            const variant_details2 = getValues("variant_two_details");
            const buttonName =
                `${variant_details2?.[0]?.s_sub_attribute?.name ?? "Unknown"}`
            setselectedButton3(buttonName?.trim())
        }


    }, [getValues("variant_details")])


    return (
        <>
            <div className="product-information">
                <div className="manufacture-bar">
                    {/* <p>Manufacturing Capacity</p> */}
                    <div className="row">
                        {/* <div className="col-4 mt-2">
                            <ProgressBar>
                                <ProgressBar striped variant="danger" now={30} key={1} />
                                <ProgressBar variant="warning" now={40} key={2} />
                                <ProgressBar striped variant="success" now={30} key={3} />
                            </ProgressBar>
                        </div> */}

                        {/* <div className="col-4">
                            <div className="show-percentage">
                                <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" className="exclamation-icon" />
                                <div className="used-capacity">
                                    <div className="d-flex">
                                        <span className="red-circle me-1"></span> <p>Capacity Used : 30 pcs</p>
                                    </div>
                                    <div className="d-flex">
                                        <span className="green-circle me-1"></span> <p>Total Capacity: 100 pcs</p>
                                    </div>
                                    <div className="d-flex">
                                        <span className="yellow-circle me-1"></span>
                                        <p>Remaining Capacity : 30 pcs</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <h1 className="text-outer-five">Colour: Celadon Marble</h1> */}
                <div className="images-cards">

                    {/* <div className="phone-color">
                        <img src={process.env.PUBLIC_URL + "/assets/category/color1.png"} className="icon" />
                    </div>
                    <div className="phone-color">
                        <img src={process.env.PUBLIC_URL + "/assets/category/color2.png"} className="icon" />
                    </div> */}
                </div>



                <div className="priceSection">
                    <p>₹ {getValues("selling_price")}</p>
                    <p>₹ {getValues("mrp")}</p>
                </div>

                {singleVariant && (
                    <>
                        <p className="product-id mt-2">Variations</p>
                        <p className="quantity">1. {getValues("variant")}</p>

                        <div className="standard-section">
                            {getValues("variant_details")?.map((item, index) => {

                                const buttonName = `${item?.s_sub_attribute?.name ?? "Unknown"}`.trim();


                                return (
                                    <Button
                                        className={
                                            selectedButton === buttonName
                                                ? "selected-btn"
                                                : "unselected-btn"
                                        }
                                        key={item?.id ?? index}
                                        onClick={async () => {
                                            setselectedButton(buttonName);

                                            await onChangeVariant(item, "Single", "1");
                                            await setImages(item?.s_p_v_a_d_images);
                                            setDynamicImages(item?.s_p_v_a_d_images);
                                        }}
                                    >
                                        {item?.s_sub_attribute
                                            ?.name ?? "Unknown"}
                                    </Button>
                                )

                            })}
                        </div>
                        {/* <p className="product-id">Variations</p>
                      <p className="quantity">1. {getValues("variant")}</p>

                      <div className="main-section-tabs">
                        {getValues("variant_details")?.map((item, index) => (
                          <div className="background-color-sofa" key={index}>
                            {" "}
                            <img
                              src={IMG_URL + item.thumbnail}
                              className="product"
                              alt="Check"
                              onClick={async () => {
                                await onChangeVariant(item, "Single", "1");
                                await setImages(item?.s_p_v_a_d_images);
                              }}
                            />
                          </div>
                        ))}
                      </div> */}
                    </>
                )}

                {MultipleVariantTwo && (
                    <>
                        <p className="product-id">Variations</p>
                        <p className="quantity">1. {getValues("variant")}</p>

                        <div className="standard-section">
                            {getValues("variant_details")?.map((item, index) => {

                                const buttonName = `${item?.s_sub_attribute?.name ?? "Unknown"}`.trim();


                                return (
                                    <Button
                                        className={
                                            selectedButton === buttonName
                                                ? "selected-btn"
                                                : "unselected-btn"
                                        }
                                        key={item?.id ?? index}
                                        onClick={async () => {

                                            setselectedButton(buttonName);

                                            if (getValues("variant_one_details")) {
                                                const variant_details2 = getValues("variant_one_details");
                                                const buttonName =
                                                    `${variant_details2?.[0]?.s_sub_attribute?.name ?? "Unknown"}`
                                                setselectedButton2(buttonName?.trim())
                                            }

                                            if (getValues("variant_two_details")) {
                                                const variant_details2 = getValues("variant_two_details");
                                                const buttonName =
                                                    `${variant_details2?.[0]?.s_sub_attribute?.name ?? "Unknown"}`
                                                setselectedButton3(buttonName?.trim())
                                            }

                                            await setValue(
                                                "variant_one",
                                                item?.s_p_v_a_one_d_as?.[0]?.s_attribute
                                                    ?.name ?? ""
                                            );

                                            await setValue(
                                                "variant_one_details",
                                                item?.s_p_v_a_one_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_ds ?? []
                                            );

                                            await setImages(
                                                item?.s_p_v_a_one_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                                    ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                            );

                                            setDynamicImages(item?.s_p_v_a_one_d_as?.[0]
                                                ?.s_p_v_a_one_d_a_ds?.[0]
                                                ?.s_p_v_a_one_d_a_d_a_d_images ?? []);
                                        }}
                                    >
                                        {item?.s_sub_attribute?.name ?? "Unknown"}
                                    </Button>
                                )
                            })}
                        </div>

                        <p className="quantity">2. {getValues("variant_one")}</p>

                        <div className="standard-section">
                            {getValues("variant_one_details")?.map(
                                (item, index) => {

                                    const buttonName = `${item?.s_sub_attribute?.name ?? "Unknown"}`.trim();


                                    return (
                                        <Button
                                            className={
                                                selectedButton2 === buttonName
                                                    ? "selected-btn"
                                                    : "unselected-btn"
                                            }
                                            key={item?.id ?? index}
                                            onClick={() => {

                                                setselectedButton2(buttonName);

                                                onChangeVariant(item, "Multiple", "2");
                                                setImages(
                                                    item?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                                );
                                                setDynamicImages( item?.s_p_v_a_one_d_a_d_a_d_images ?? [])
                                            }}
                                        >
                                            {item?.s_sub_attribute?.name ?? "Unknown"}
                                        </Button>
                                    )
                                }
                            )}
                        </div>
                    </>
                )}

                {MultipleVariantThree && (
                    <>
                        <p className="product-id">Variations</p>
                        <p className="quantity">1. {getValues("variant")}</p>

                        <div className="standard-section">
                            {getValues("variant_details")?.map((item, index) => {

                                const buttonName =
                                    `${item?.s_sub_attribute?.name ?? "Unknown"}`

                                return (
                                    <Button
                                        className={
                                            selectedButton === buttonName?.trim()
                                                ? "selected-btn"
                                                : "unselected-btn"
                                        }
                                        key={item?.id ?? index}
                                        onClick={async () => {

                                            console.log("selectedItem", item)

                                            setselectedButton(buttonName);
                                            if (getValues("variant_one_details")) {
                                                const variant_details2 = getValues("variant_one_details");
                                                const buttonName =
                                                    `${variant_details2?.[0]?.s_sub_attribute?.name ?? "Unknown"}`
                                                setselectedButton2(buttonName?.trim())
                                            }

                                            if (getValues("variant_two_details")) {
                                                const variant_details2 = getValues("variant_two_details");
                                                const buttonName =
                                                    `${variant_details2?.[0]?.s_sub_attribute?.name ?? "Unknown"}`
                                                setselectedButton3(buttonName?.trim())
                                            }

                                            await setValue(
                                                "variant_one",
                                                item?.s_p_v_a_one_d_as?.[0]?.s_attribute
                                                    ?.name ?? ""
                                            );

                                            await setValue(
                                                "variant_one_details",
                                                item?.s_p_v_a_one_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_ds ?? []
                                            );

                                            setValue(
                                                "variant_two",
                                                item?.s_p_v_a_one_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                                    ?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
                                            );

                                            setValue(
                                                "variant_two_details",
                                                item?.s_p_v_a_one_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                                    ?.s_p_v_a_one_d_a_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_d_a_ds
                                            );

                                            setValue(
                                                "sku_id",
                                                item?.s_p_v_a_one_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                                    ?.s_p_v_a_one_d_a_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
                                            );
                                            setValue(
                                                "mrp",
                                                item?.s_p_v_a_one_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                                    ?.s_p_v_a_one_d_a_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                    ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                                            );

                                            setValue(
                                                "selling_price",
                                                item?.s_p_v_a_one_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                                    ?.s_p_v_a_one_d_a_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                    ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
                                            );

                                            setImages(
                                                item?.s_p_v_a_one_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                                    ?.s_p_v_a_one_d_a_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                    ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                            );

                                            setDynamicImages(item?.s_p_v_a_one_d_as?.[0]
                                                ?.s_p_v_a_one_d_a_ds?.[0]
                                                ?.s_p_v_a_one_d_a_d_as?.[0]
                                                ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                ?.s_p_v_a_one_d_a_d_a_d_images ?? [])


                                        }}
                                    >
                                        {item?.s_sub_attribute?.name ?? "Unknown"}
                                    </Button>
                                )
                            })}
                        </div>

                        <p className="quantity">2. {getValues("variant_one")}</p>

                        <div className="standard-section">
                            {getValues("variant_one_details")?.map(
                                (item, index) => {

                                    const buttonName = `${item?.s_sub_attribute?.name ?? "Unknown"}`
                                    return (
                                        <Button
                                            className={
                                                selectedButton2 === buttonName?.trim()
                                                    ? "selected-btn"
                                                    : "unselected-btn"
                                            }
                                            key={item?.id ?? index}
                                            onClick={() => {
                                                setselectedButton2(buttonName);
                                                if (getValues("variant_two_details")) {
                                                    const variant_details2 = getValues("variant_two_details");
                                                    const buttonName =
                                                        `${variant_details2?.[0]?.s_sub_attribute?.name ?? "Unknown"}`
                                                    setselectedButton3(buttonName?.trim())
                                                }

                                                setValue(
                                                    "variant_two",
                                                    item?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute
                                                        ?.name
                                                );

                                                setValue(
                                                    "variant_two_details",
                                                    item?.s_p_v_a_one_d_a_d_as?.[0]
                                                        ?.s_p_v_a_one_d_a_d_a_ds
                                                );

                                                setValue(
                                                    "sku_id",
                                                    item?.s_p_v_a_one_d_a_d_as?.[0]
                                                        ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
                                                );
                                                setValue(
                                                    "mrp",
                                                    item?.s_p_v_a_one_d_a_d_as?.[0]
                                                        ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                        ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                                                );

                                                setValue(
                                                    "selling_price",
                                                    item?.s_p_v_a_one_d_a_d_as?.[0]
                                                        ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                        ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
                                                );

                                                setImages(
                                                    item?.s_p_v_a_one_d_a_d_as?.[0]
                                                        ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                        ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                                );
                                                setDynamicImages( item?.s_p_v_a_one_d_a_d_as?.[0]
                                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                    ?.s_p_v_a_one_d_a_d_a_d_images ?? []);
                                            }}
                                        >
                                            {item?.s_sub_attribute?.name ?? "Unknown"}
                                        </Button>
                                    )

                                }
                            )}
                        </div>

                        <p className="quantity">3. {getValues("variant_two")}</p>

                        <div className="standard-section">
                            {getValues("variant_two_details")?.map(
                                (item, index) => {

                                    const buttonName =
                                        `${item?.s_sub_attribute?.name ?? "Unknown"}`

                                    return (
                                        <Button
                                            className={
                                                selectedButton3 === buttonName?.trim()
                                                    ? "selected-btn"
                                                    : "unselected-btn"
                                            }
                                            key={item?.id ?? index}
                                            onClick={() => {
                                                setselectedButton3(buttonName);

                                                onChangeVariant(item, "Multiple", "3");
                                                setImages(
                                                    item?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                                );

                                                setDynamicImages(item?.s_p_v_a_one_d_a_d_a_d_images ?? []);
                                            }}
                                        >
                                            {item?.s_sub_attribute?.name ?? "Unknown"}
                                        </Button>
                                    )

                                }
                            )}
                        </div>
                    </>
                )}

                <Row className="mt-4 mb-5">
                    <Col md={1} sm={3}>
                        <div className="Warranty">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/category/quality.png"}
                                alt=""
                                className="icon"
                            />
                        </div>
                        <p className="year">1 Year Warranty</p>
                    </Col>
                    <Col md={2} sm={3}>
                        <div className="Warranty">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/category/change.png"}
                                alt=""
                                className="icon"
                            />
                        </div>
                        <p className="year">10 Days Replacement</p>
                    </Col>
                </Row>

                {/* <div className="brand-holder">
                    <p>
                        Brand :<span>{data?.s_brand?.name}</span>
                    </p>
                </div> */}
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Product Highlights</Accordion.Header>
                        <Accordion.Body>
                            {/* <h4 className="product-text">
                                Product Highlight: Luxurious Comfort and Timeless Elegance with Our Sofa
                            </h4> */}
                            <p className="product-text">{htmlToReactParser.parse(data?.s_product_information?.p_hightlights)}</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Manufacturer Details</Accordion.Header>
                        <Accordion.Body>
                            {/* <h4 className="product-text">
                                Product Highlight: Luxurious Comfort and Timeless Elegance with Our Sofa
                            </h4> */}
                            <p className="product-text">{htmlToReactParser.parse(data?.s_product_information?.meta_description)}</p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
};

export default FirstVenderPage;
