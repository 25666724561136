import React, { useState, useEffect, useContext } from "react";
import './WhyChoose.css'
import { Context } from "../../../utils/context";
import { getwhychooseus } from "../../../utils/apis/common/Common";

const WhyChoose = () => {

    var HtmlToReactParser = require("html-to-react").Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const [whychooseus, setwhychooseus] = useState();
    const { getData, IMG_URL, sellerId, sellerDomain } = useContext(Context);
    const getProject = async (data) => {
        const res = await getwhychooseus(sellerDomain);
        if (res?.success) {
            setwhychooseus(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getProject();
    }, [sellerDomain]);
    const features = [
        {
            icon: "/assets/about/free-shipping.png",
            title: "Free Shipping",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr."
        },
        {
            icon: "/assets/about/coin.png",
            title: "100% Money Back Guarantee",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr."
        },
        {
            icon: "/assets/about/headphones.png",
            title: "Online Support 24/7",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr."
        }
    ];
    return (
        <>
            <section className="why-choose">
                <div className="text-center">
                    <h3 className="hot-title">Why Choose Us</h3>
                    <div className="title-border"></div>
                </div>
                <div className="container mt-5">
                    <div className="row">
                        {whychooseus?.map((feature, index) => (
                            <div key={index} className={"col-lg-4 bd-right"}>
                                <div className="d-flex choos-col">
                                    <div className="choose-fafa-bg text-center">
                                        <img src={IMG_URL + feature?.logo} className="choose-fafa-icons" alt={feature?.name} />
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="title">{htmlToReactParser.parse(feature?.name)}</h6>
                                        <p className="sub-text">{htmlToReactParser.parse(feature?.description)}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChoose