import { React, useState } from "react";
import "./CategoryTabs.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Container, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Rating } from "react-simple-star-rating";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import ProgressBar from "react-bootstrap/ProgressBar";
const CategoryTabs = (props) => {
  console.log(props, 'pppppppppppppppppppppppppppppppppppppppppp');
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const { data, singleVariant, product } = props;
  const [activeTab, setActiveTab] = useState("first");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const now = 60;
  const [showSeeking, setShowSeeking] = useState(false);
  // Eight tab section
  const ReviewsDetails = [
    {
      name: "Shubham Kumar",
      date: "May 16, 2023",
      text: "very nice experience....super quality materials.",

      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2bSellerProduct/review1.png",
    },
    {
      name: "Rohit Verma",
      date: "June 10, 2023",
      text: "very helpful and good stuffs thank you so much",

      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2bSellerProduct/review2.png",
    },
    {
      name: "Rohit Verma",
      date: "June 10, 2023",
      text: "very helpful and good stuffs thank you so much",

      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2bSellerProduct/review3.png",
    },
  ];

  const QutionsDetails = [
    {
      customer:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
      name: "Oshin Kawale",
      question:
        "What factors should I consider before purchasing a product online?",
      customertwo:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
      customername: "Pratiksha Pethe",
      ans: "Before making a purchase online, consider factors such as product reviews, seller reputation, return policies, shipping costs, and product specifications to ensure you make an informed decision.",
    },
    {
      customer:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
      name: "Oshin Kawale",
      question:
        "What factors should I consider before purchasing a product online?",
      customertwo:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
      customername: "Pratiksha Pethe",
      ans: "Before making a purchase online, consider factors such as product reviews, seller reputation, return policies, shipping costs, and product specifications to ensure you make an informed decision.",
    },
  ];

  return (
    <>
      {/* B2bSellerProduct section started */}
      <section className="B2bSeller-Product">
        <Container>
          <Row>
            <Col xxl={12}>
              <div className="Product-section">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="first"
                  activeKey={activeTab}
                  onSelect={handleTabChange}
                >
                  <div className="main-nav">
                    <Nav variant="pills" className="">
                      <Nav.Item>
                        <Nav.Link eventKey="first">About Product</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          Product Description
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          Technical Specification
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="fifth">
                          Warranty & Product Caution
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>

                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      {/* Content of first tab */}
                      <div className="Content-First-Tab">
                        <div
                          className="Content-First-Tab"
                          dangerouslySetInnerHTML={{ __html: data?.about }}
                        ></div>
                        <Row>
                          <Col lg={7}>
                            <p className="text-lorem">{htmlToReactParser.parse(product?.about)}
                            </p>
                          </Col>
                        </Row>
                      </div>

                      {/* Content of second tab */}
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      {/* Content of second tab started*/}
                      <div className="Content-Second-Tab">
                        <div className="content-one">
                          <p className="title-tab">
                            {htmlToReactParser.parse(product?.s_product_information?.p_description)}
                          </p>
                          <p className="title-tab">
                            {htmlToReactParser.parse(product?.s_product_information?.p_hightlights)}
                          </p>
                        </div>
                       
                        
                      </div>

                      {/* Content of second tab end*/}
                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                      {/* Content of third tab started */}
                     

                      <div
                        className="Content-third-Tab"
                        dangerouslySetInnerHTML={{ __html: product?.about }}
                      ></div>

                      {/* Content of third tab end */}
                    </Tab.Pane>

                    <Tab.Pane eventKey="fifth">
                      {/* Fifth Tab Content started */}
                     

                      <div
                        className="Content-fifth-Tab"
                        dangerouslySetInnerHTML={{
                          __html: product?.s_product_information?.p_hightlights,
                        }}
                      ></div>
                      {/* Fifth Tab Content end */}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* B2bSellerProduct section End */}
    </>
  );
};

export default CategoryTabs;
