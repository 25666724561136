import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faCircle } from "@fortawesome/free-solid-svg-icons";
import Incre_Decre_btn from "../Incre_Decre_btn/Incre_Decre_btn";

function Color_Cart_Holder({ circleColor }) {
  const [showIncreDecreBtn, setShowIncreDecreBtn] = useState(false);
  const circleStyle = {
    color: circleColor,
  };

  // Example data for circle colors/icons
  const circleData = ["red", "blue", "green", "yellow"];

  const handleCartClick = () => {
    setShowIncreDecreBtn(true);
  };
  return (
    <>
      <div className="Color_Cart_Holder Product_Details_Div ">
        <div className="color_cart_holder">
          <div className="color_option_holder">
            {circleData.map((color, index) => (
              <FontAwesomeIcon
                key={index}
                className="circleClassName"
                style={{ ...circleStyle, color }}
                icon={faCircle}
              />
            ))}
          </div>

          <div className="Cart_Holder">
            {!showIncreDecreBtn && (
              <FontAwesomeIcon
                className="CartClassName"
                icon={faCartShopping}
                onClick={handleCartClick}
              />
            )}
            {showIncreDecreBtn && <Incre_Decre_btn />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Color_Cart_Holder;
