import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import "./Wishlist.css";
import ProductCards from "../Common_Component/ProductCards/ProductCards";
const Wishlist = () => {
    const MyWishlistCards = [
        {
            images: process.env.PUBLIC_URL + "/assets/Homepage/Trending/mobile.png",
            cardtitle: "Oneplus Nord CE4 (Celadon Marble, 8GB RAM, 256GB Storage)",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/ProductCards/camera.png",
            cardtitle: "Oneplus Nord CE4 (Celadon Marble, 8GB RAM, 256GB Storage)",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/Homepage/Trending/men.png",
            cardtitle: "Oneplus Nord CE4 (Celadon Marble, 8GB RAM, 256GB Storage)",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/Homepage/Trending/bag.png",
            cardtitle: "Oneplus Nord CE4 (Celadon Marble, 8GB RAM, 256GB Storage)",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/Homepage/Trending/mobile.png",
            cardtitle: "Oneplus Nord CE4 (Celadon Marble, 8GB RAM, 256GB Storage)",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/ProductCards/camera.png",
            cardtitle: "Oneplus Nord CE4 (Celadon Marble, 8GB RAM, 256GB Storage)",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/Homepage/Trending/men.png",
            cardtitle: "Oneplus Nord CE4 (Celadon Marble, 8GB RAM, 256GB Storage)",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/Homepage/Trending/bag.png",
            cardtitle: "Oneplus Nord CE4 (Celadon Marble, 8GB RAM, 256GB Storage)",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/Homepage/Trending/mobile.png",
            cardtitle: "Oneplus Nord CE4 (Celadon Marble, 8GB RAM, 256GB Storage)",
        },
       
    ];
    const [showAll, setShowAll] = useState(false);
    const [numToShow, setNumToShow] = useState(4); // State to track number of cards to show

    const toggleShowAll = () => {
        setShowAll(!showAll);
        if (showAll) {
            // If currently showing all, reduce the number of rows displayed
            setNumToShow(8);
        } else {
            // If currently not showing all, show all rows
            setNumToShow(MyWishlistCards.length);
        }
    };
    return (
        <>
            <section className="Wishlist">
                <div className="Wishlist-Banner">
                    <img
                        src={process.env.PUBLIC_URL + "/assets/Homepage/Banner/Banner1.png"}
                        className="wishlist-banner"
                    />
                </div>
                <Container>
                    <div className="Wishlist-Cards">
                        <Row className="mt-3">
                            {MyWishlistCards.slice(0, numToShow).map((item, index) => (
                                <Col xxl={3} xl={3} lg={6} md={6} sm={6} xs={12} key={index}>
                                    <ProductCards images={item.images} imgClassName="demo" cardtitle={item.cardtitle} />
                                </Col>
                            ))}

                            {showAll ? (
                                <div className="view-all mt-4" onClick={toggleShowAll}>
                                    <p className="view">View Less</p>
                                </div>
                            ) : (
                                <div className="view-all mt-4" onClick={toggleShowAll}>
                                    <p className="view">View All</p>
                                </div>
                            )}
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default Wishlist;
