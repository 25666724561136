import React, { useContext, useEffect, useState } from 'react'
import CategoryBanner from './category-banner/CategoryBanner'
import CategoryCard from './category-banner/Category-card/CategoryCard'
import { Helmet } from 'react-helmet'
import { GetAppSetup } from '../../utils/apis/master/Master'
import { SubCategory } from '../../utils/apis/Product/Product'
import { Context } from '../../utils/context'
import { getData } from '../../utils/api'

const Category = () => {

  const [appSetUpData, setAppSetUpData] = useState()
  const { sellerDomain, type } = useContext(Context);
  const [category, setCategory] = useState("");
  const [metaData, setMetaData] = useState()




  const getCategoryData = async () => {
    const res = await getData(`/info-website/home/category?domain_id=${sellerDomain}&type=${type}`);
    if (res?.success) {
      setCategory(res?.data);
    }
  }


  const getAppSetUpApiUrl = async () => {
    const res = GetAppSetup(sellerDomain)

    if (res?.success) {
      setAppSetUpData(res?.data)
    }
  }

  useEffect(() => {

    if (category?.length > 0) {
      const fetchCategoryName = category?.map((item) => {
        return item?.name
      })
      setMetaData(fetchCategoryName)
    }

  }, [category])

  useEffect(() => {
    getAppSetUpApiUrl()
    getCategoryData()
  }, [])

  console.log("appSetUpData", appSetUpData)

  return (
    <>

      <Helmet>
        <title>Categories With Wide Range.</title>
        <meta name="description" content="Categories With Wide Range" />
        <meta name="og:site_name" content={appSetUpData?.name ? appSetUpData?.name : "Inquiry"} />
        <meta name="og:url" content={window.location.href} />
        <meta name="og:type" content={"category"} />
        <meta name="keywords" content={metaData?.join(', ')} />
      </Helmet>

      <CategoryBanner />
      <CategoryCard />
    </>
  )
}

export default Category